@charset "UTF-8";
@import url('https://fonts.googleapis.com/css?family=Poppins:400,400i,600,600i&display=swap');

.nav {
    /* list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
     */


    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: stretch;
    align-content: flex-end;
    width: 100%;
    /* margin-top: 2vh; */
    margin: 2vh;
    list-style-type: none;
}
  
.nav.nav.page-home a {
    margin-right: 3vh;
    color: black;
    text-decoration: none;
    font-weight: bold;
}
  
.nav a:hover {

}

.nav a {
    margin-right: 3vh;
    color: black;
    text-decoration: none;
    font-weight: bold;
}

.nav.page-home .navHomeLink {
    display: none;
}

.navHomeLink {
    flex-grow: 1;
    margin-left: 3vh;
}